<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Entry User</th>
              <th>Requisition No.</th>
              <th>Status</th>
              <th>Created_at</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, i) in requisitions.data" :key="i">
              <td>{{ requisitions.from + i}}</td>
              <td>{{ requisition.requisition_creator_user.name }}</td>
              <td>{{ requisition.requisition_number }}</td>
              <td>
                <a-tag color="#f50" v-if="requisition.requisition_status === 'Open'">
                  Open
                </a-tag>
                <a-tag color="#87d068" v-else>
                  Closed
                </a-tag>
              </td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <a-button class="btn btn-sm btn-success mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(requisition.id, index = i)"> <i class="fa fa-file-pdf-o mr-1"></i> Download</a-button>
                <router-link :to="{ name: 'hubViewRequisition', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  components: { Empty },
  data() {
    return {
      requisitions: {},
      loading: false,
      loader: false,
      flag: false,
      btnLoading: false,
      index: -1,
      pdfDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/hub/requisition/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.requisitions = response.data.requisitions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    pdfDownload(requisitionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/report/requisition/pdf-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'requisition.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
